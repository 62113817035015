import { Text, Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import TabListComponent from 'components/common/TabListComponent';
import Section from 'components/common/Section';
import Container from '../Container';

export default function PriceTab({ data, titleLink, info }) {
  return (
    <Section>
      {titleLink ? (
        <NextLink href={titleLink || '#'} passHref prefetch={false}>
          <Link>
            <Text
              as={'h2'}
              mb={'20px'}
              fontSize={'36px'}
              fontWeight={'400'}
              textAlign="center"
              paddingTop={{ base: '30px', md: '80px' }}
            >
              {data.label}
            </Text>
          </Link>
        </NextLink>
      ) : (
        <Text
          as={'h2'}
          mb={'20px'}
          fontSize={'36px'}
          fontWeight={'400'}
          textAlign="center"
          paddingTop={{ base: '30px', md: '80px' }}
        >
          {data.label}
        </Text>
      )}

      <TabListComponent data={data} info={info} />
      {titleLink === '/el/best-sellers' && (
        <Container>
          <div
            id="sbl_recommendations"
            data-widget-type="homepage-recommendations"
            data-widget-uid="BzTnw2Lkt245"
          ></div>
        </Container>
      )}
    </Section>
  );
}
