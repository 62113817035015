import { Box, Image, Grid, GridItem, Button, Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import Container from 'components/common/Container';
import Tracking from 'utils/tracking';

const Banner = ({ data, columns, position }) => {
  const image0 = data?.image[0]?.url;
  const image1 = data?.image[1]?.url;

  return (
    <Box
      mt={{ base: '40px', md: '0px' }}
      mb={{ base: '40px', md: '80px' }}
      as={'div'}
      onClick={() => Tracking.selectPromotion(data?.title, `${data?.image?.length + 1} Cards Mid`, position + 1)}
    >
      <Container>
        <NextLink href={data?.cta?.url || '#'} passHref prefetch={false}>
          <Link data-promoid={data?.title} title={data?.title} _hover={{ textDecorati: 'none' }}>
            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
              marginLeft={{ base: '-20px', md: '0' }}
              marginRight={{ base: '-20px', md: '0' }}
            >
              {columns === 2 ? (
                <Image
                  w={'100%'}
                  h={{ base: '300px', lg: '650px' }}
                  objectFit={'cover'}
                  src={image0}
                  alt={data?.title}
                />
              ) : null}

              <Box
                background={'brand.900'}
                h={{ base: '300px', lg: '650px' }}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'space-around'}
                p={{ base: '15px 15px', lg: '15px 25px' }}
                textAlign={'center'}
              >
                <Box as="p" textStyle="bannerP">
                  {data?.subtitle}
                </Box>
                <Box _hover={{ textDecoration: 'underline' }} as="h2" textStyle="textStyleH">
                  {data?.title}
                </Box>
                <NextLink href={data?.cta?.url}>
                  <Link
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    <Button
                      w={'250px'}
                      display={'flex'}
                      variant={'outlineInverted'}
                      onClick={() =>
                        Tracking.selectPromotion(data?.title, `${data?.image?.length + 1} Cards Mid`, position + 1)
                      }
                    >
                      {data?.cta?.title}
                    </Button>
                  </Link>
                </NextLink>
              </Box>
              <GridItem colSpan={columns === 2 ? 1 : 2}>
                <Image
                  w={'100%'}
                  h={{ base: '300px', lg: '650px' }}
                  objectFit={'cover'}
                  src={columns === 2 ? image1 : image0}
                  alt={data?.title}
                />
              </GridItem>
            </Grid>{' '}
          </Link>
        </NextLink>
      </Container>
    </Box>
  );
};

export default Banner;
